import * as React from 'react'
import { graphql } from 'gatsby'

import DayPrograms from '../components/channel/DayPrograms'

export const ProgramsListSlice = ({ slice, context }) => {
    const { uid, programs } = context

    return <DayPrograms programs={programs} channelSlug={uid} />
}

export const query = graphql`
    fragment ProgramsPageDataChannelBodyList on PrismicProgramsPageDataChannelBodyList {
        id
    }
`
