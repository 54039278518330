import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import { PrismicRichText, SliceZone } from '@prismicio/react'
import moment from 'moment'
import 'moment/locale/fr'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import { days } from '@/utils/helpers'

import { Text } from '@/slices/Text'
import { AdSlotSlice } from '@/slices/AdSlot'
import { ProgramsDropdownSlice } from '@/slices/ProgramsDropdowns'
import { ProgramsListSlice } from '@/slices/ProgramsList'
import { ThumbnailsSlice } from '@/slices/Thumbnails'

moment.locale('fr')

const ChannelPrograms = ({ data, pageContext }) => {
    const [activeDay, setActiveDay] = React.useState(
        moment().format('dddd DD/MM')
    )
    const [programs, setPrograms] = React.useState(
        data.swapi?.programs?.filter(
            p =>
                moment.utc(p.starts_at).startOf('day').format('L') ===
                moment().format('L')
        )
    )
    const [types, setTypes] = React.useState(
        [
            'Tous les genres',
            ...new Set(programs.map(c => c.subtitle).sort())
        ].map(t => ({ label: t, value: t === 'Tous les genres' ? null : t }))
    )

    // channels list
    const providerChannelsSlugs = data.allPrismicChannel?.nodes?.map(c => c.uid)
    const apiChannelsSlugs = data.swapi?.channels?.map(c => c.publisher_slug)

    const channels = data.swapi?.channels
        ?.filter(c => providerChannelsSlugs.includes(c.publisher_slug))
        .map(({ name, publisher_slug: slug }) => ({
            label: name,
            value: slug
        }))

    // init
    const [selectedChannel, setSelectedChannel] = React.useState(
        channels.find(c => c.value === pageContext.uid)
    )
    const [selectedDay, setSelectedDay] = React.useState(days[1])
    const [selectedType, setSelectedType] = React.useState()

    // handle channel change
    const handleChannelChange = v => {
        setSelectedChannel(channels.find(c => c.value === v))

        navigate(`/programme-tv-${v}`)
    }

    // handle date change
    const handleDateChange = v => {
        setSelectedDay(days.find(d => d.value === v))

        setActiveDay(moment(v, 'L').format('dddd DD/MM'))

        handleFilter(v, selectedType?.value)

        // reset types
        setTypes(
            [
                'Tous les genres',
                ...new Set(
                    data.swapi?.programs
                        ?.filter(
                            p =>
                                moment
                                    .utc(p.starts_at)
                                    .startOf('day')
                                    .format('L') === v
                        )
                        .map(c => c.subtitle)
                        .sort()
                )
            ].map(t => ({
                label: t,
                value: t === 'Tous les genres' ? null : t
            }))
        )
    }

    // handle type change
    const handleTypeChange = v => {
        setSelectedType(types.find(d => d.value === v))

        handleFilter(selectedDay.value, v)
    }

    // handle filter
    const handleFilter = (day, type) => {
        const newPrograms = data.swapi?.programs?.filter(p => {
            if (type) {
                return (
                    p.subtitle === type &&
                    moment.utc(p.starts_at).startOf('day').format('L') === day
                )
            }

            return moment.utc(p.starts_at).startOf('day').format('L') === day
        })

        setPrograms(newPrograms)
    }

    //
    const { data: channelData } = data.prismicChannel

    return (
        <Layout>
            <Seo
                title={`Programme TV de ${pageContext.name} d’aujourd’hui et de la semaine`}
                description={`Regardez les programmes de la chaîne ${pageContext.name}. Retrouvez la grille de la semaine ou consultez ce qui est diffusé en ce moment sur ${pageContext.name}, ce soir ou demain.`}
            />
            <div className="mx-auto pt-10 px-5 max-w-5xl">
                <div className="flex items-end mb-4">
                    <h1 className="text-lg sm:text-xl md:text-2xl text-gray-800">
                        Programme TV de {pageContext.name}
                    </h1>

                    <span className="ml-auto text-lg text-gray-400">
                        {activeDay}
                    </span>
                </div>

                {channelData.programs_after_page_title_text ? (
                    <div className="mb-4 prose prose-lg max-w-none text-gray-600">
                        <PrismicRichText
                            field={
                                channelData.programs_after_page_title_text
                                    .richText
                            }
                        />
                    </div>
                ) : null}

                <SliceZone
                    slices={data.prismicProgramsPage?.data?.channel_body}
                    components={{
                        text: Text,
                        ad_slot: AdSlotSlice,
                        dropdowns: ProgramsDropdownSlice,
                        thumbnails: ThumbnailsSlice,
                        list: ProgramsListSlice
                    }}
                    context={{
                        uid: pageContext.uid,
                        channels,
                        types,
                        days,
                        selectedChannel,
                        selectedType,
                        selectedDay,
                        programs,
                        swipeChannels: data.allPrismicChannel?.nodes?.filter(
                            n => apiChannelsSlugs.includes(n.uid)
                        ),
                        handleChannelChange,
                        handleTypeChange,
                        handleDateChange
                    }}
                />

                {channelData.programs_after_list_text ? (
                    <div className="mt-4 pb-20 prose prose-lg max-w-none text-gray-600">
                        <PrismicRichText
                            field={
                                channelData.programs_after_list_text.richText
                            }
                        />
                    </div>
                ) : null}
            </div>
        </Layout>
    )
}

export default ChannelPrograms

export const query = graphql`
    query ChannelProgramsQuery($uid: String!) {
        prismicProgramsPage {
            data {
                channel_body {
                    ... on PrismicSliceType {
                        slice_type
                    }
                    ...ProgramsPageDataChannelBodyText
                    ...ProgramsPageDataChannelBodyAdSlot
                    ...ProgramsPageDataChannelBodyDropdowns
                }
            }
        }
        prismicChannel(uid: { eq: $uid }) {
            data {
                programs_after_list_text {
                    richText
                }
                programs_after_page_title_text {
                    richText
                }
            }
        }
        allPrismicChannel {
            nodes {
                uid
                data {
                    name {
                        text
                    }
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 80
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
        swapi {
            channels {
                name
                publisher_slug
            }
            programs(channel_slug: $uid) {
                id
                title
                subtitle
                starts_at
                duration
                duration_in_hours
            }
        }
    }
`
