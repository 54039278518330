import moment from 'moment'
import 'moment/locale/fr'

moment.locale('fr')

export const days = []
const yestarday = moment().subtract(1, 'days')

days.push({
    label: yestarday.format('ddd DD/MM'),
    value: yestarday.format('L')
})

for (var i = 0; i < 7; i++) {
    const d = moment().add(i, 'days')

    days.push({
        label: d.format('ddd DD/MM'),
        value: d.format('L')
    })
}
