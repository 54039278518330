import * as React from 'react'
import { graphql } from 'gatsby'

import SwipeStream from '../components/SwipeStream'

export const ThumbnailsSlice = ({ slice, context }) => {
    const { swipeChannels } = context

    return (
        <SwipeStream
            className="z-0 mb-10 w-full"
            channels={swipeChannels}
            programs={true}
        />
    )
}

export const query = graphql`
    fragment ProgramsPageDataChannelBodyThumbnails on PrismicProgramsPageDataChannelBodyThumbnails {
        id
    }
`
