import * as React from 'react'
import moment from 'moment'
import { Link } from 'gatsby'

export default function DayPrograms({ programs, channelSlug }) {
    return (
        <div className="mx-auto mb-10 max-w-xl">
            {programs.map(
                ({
                    id,
                    title,
                    subtitle,
                    starts_at,
                    duration,
                    duration_in_hours
                }) => (
                    <article
                        className="py-4 text-sm border-b border-gray-200 last:border-b-0"
                        key={id}
                    >
                        <header className="grid grid-cols-3 gap-10">
                            <div>
                                <span className="text-gray-500">
                                    {moment.utc(starts_at).format('HH:mm')}
                                </span>
                            </div>
                            <div className="col-span-2">
                                <h1 className="mb-2 font-bold leading-tight hover:underline">
                                    <Link
                                        to={`/regarder-${channelSlug}-en-direct`}
                                        title={`Regarder ${title} en direct`}
                                    >
                                        {title}
                                    </Link>
                                </h1>
                                <div className="flex flex-col gap-1">
                                    <span className="text-gray-500">
                                        {subtitle}
                                    </span>
                                    <span className="font-medium text-gray-700">
                                        {duration < 60
                                            ? `${duration}m`
                                            : duration_in_hours.replace(
                                                  ':',
                                                  'h'
                                              )}
                                    </span>
                                </div>
                            </div>
                        </header>
                    </article>
                )
            )}
        </div>
    )
}
