import * as React from 'react'
import { graphql } from 'gatsby'

import CustomSelect from '../components/CustomSelect'

export const ProgramsDropdownSlice = ({ slice, context }) => {
    const {
        channels,
        types,
        days,
        selectedChannel,
        selectedType,
        selectedDay,
        handleChannelChange,
        handleDateChange,
        handleTypeChange
    } = context

    return (
        <div className="grid sm:grid-cols-3 gap-4 mb-8 p-4 bg-gray-100">
            <CustomSelect
                list={channels}
                selected={selectedChannel}
                setSelected={handleChannelChange}
            />

            <CustomSelect
                list={days}
                selected={selectedDay}
                setSelected={handleDateChange}
            />

            <CustomSelect
                list={types}
                selected={selectedType}
                setSelected={handleTypeChange}
                placeholder="Filtrer par genre"
            />
        </div>
    )
}

export const query = graphql`
    fragment ProgramsPageDataChannelBodyDropdowns on PrismicProgramsPageDataChannelBodyDropdowns {
        id
    }
`
